import FormRichTextEditor from "@components/forms/FormRichTextEditor";
import MultipleUsersAutocomplete from "@components/forms/MultipleUsersAutocomplete";
import { DEFAULT_EMAIL1, DEFAULT_EMAIL2, DEFAULT_EMAIL3 } from "@constants";
import { OrderStatus } from "@enums/BE/OrderStatus";
import { PinType } from "@enums/BE/PinType";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateOrderNote } from "@hooks/notes/useCreateOrderNote";
import { useChangeStatus } from "@hooks/orders/useChangeStatus";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import { useActiveOrderStore } from "@store/useActiveOrderStore";
import { NoteDto } from "@types";
import { editNoteSchema } from "@validation/noteSchema";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BasicModal from "./BasicModal";

const getDefaultValues = (orderId: number): NoteDto => ({
  orderId,
  emails: [DEFAULT_EMAIL1, DEFAULT_EMAIL2, DEFAULT_EMAIL3],
  text: "",
  pinType: PinType.None,
});

type Props = {
  handleClose: (() => void) | null;
  handleConfirm?: (callback?: () => void) => void;
  isLoading: boolean;
};

const AccountingStatusChangeConfirmation: FC<Props> = ({
  handleClose,
  handleConfirm,
  isLoading,
}) => {
  const { palette } = useTheme();
  const { order } = useActiveOrderStore();

  const formMethods = useForm<NoteDto>({
    defaultValues: getDefaultValues(order?.id || 0),
    resolver: yupResolver(editNoteSchema),
  });

  const { createOrderNote, isCreatingOrderNote } = useCreateOrderNote();
  const { mutate: changeStatus, isPending: changingStatus } = useChangeStatus();

  const onSave = (data: NoteDto) => {
    const actionsOnSave = () => {
      changeStatus({
        orderId: order?.id ?? 0,
        status: OrderStatus.AuditRevision,
      });
      createOrderNote(data);
      handleClose?.();
    };

    handleConfirm?.(actionsOnSave);
  };

  const [initialMarkupSet, setInitialMarkupSet] = useState(false);

  return (
    <FormProvider {...formMethods}>
      <BasicModal
        handleClose={handleClose}
        title="Changing Order Details"
        maxWidth="md"
        handleConfirm={onSave}
        formMethods={formMethods}
        confirmBtnText="Save"
        isLoadingConfirm={isLoading || isCreatingOrderNote || changingStatus}>
        <p
          style={{
            color: palette.error.light,
            fontSize: "1.35rem",
            fontStyle: "italic",
            marginBottom: "2rem",
          }}>
          Are you sure? This order has already been audited and will require a
          subsequent review.
        </p>

        <div style={{ marginBottom: "2rem" }}>
          <p style={{ fontWeight: 500, marginBottom: "1rem" }}>
            Who should be notified?
          </p>

          <MultipleUsersAutocomplete
            emails={formMethods.watch("emails")}
            setEmails={(values) => formMethods.setValue("emails", values)}
          />
        </div>

        <FormRichTextEditor
          placeholder="Add note here"
          fieldName="text"
          markup={""}
          error={(formMethods.formState?.errors?.text?.message as string) || ""}
          initialMarkupSet={initialMarkupSet}
          setInitialMarkupSet={setInitialMarkupSet}
        />

        {order?.status !== OrderStatus.AuditRevision ? (
          <Alert
            severity="warning"
            sx={{ alignItems: "center", marginTop: "1rem" }}>
            <span>
              By saving the changes, the status for this order will change to
            </span>{" "}
            <span style={{ fontWeight: 500 }}>Audit Revision</span>
          </Alert>
        ) : null}
      </BasicModal>
    </FormProvider>
  );
};

export default AccountingStatusChangeConfirmation;
