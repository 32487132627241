import FormHelperText from "@mui/material/FormHelperText";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { FC, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormContext } from "react-hook-form";

const toolbarOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "history",
  ],
};

type Props = {
  fieldName: string;
  markup: string;
  placeholder: string;
  initialMarkupSet: boolean;
  setInitialMarkupSet: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
};

const FormRichTextEditor: FC<Props> = ({
  fieldName,
  markup,
  placeholder,
  initialMarkupSet,
  setInitialMarkupSet,
  error,
  readOnly,
  onChange,
}) => {
  const formMethods = useFormContext();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!initialMarkupSet) {
      if (markup) {
        const blocksFromHtml = htmlToDraft(markup);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const state = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(state));
      } else {
        setEditorState(EditorState.createEmpty());
      }
      setInitialMarkupSet?.(true);
    }
  }, [markup, initialMarkupSet, setInitialMarkupSet]);

  const handleChange = (state: EditorState) => {
    setEditorState(state);

    if (initialMarkupSet) {
      const rawContentState = convertToRaw(state.getCurrentContent());
      const isEmpty = !rawContentState.blocks.some(
        (block) => !!block.text.trim().length
      );

      if (isEmpty) {
        onChange
          ? onChange("")
          : formMethods?.setValue(fieldName, "", { shouldDirty: true });
      } else {
        const value = draftToHtml(rawContentState).replace(/\n$/, "");

        onChange
          ? onChange(value)
          : formMethods?.setValue(fieldName, value, {
              shouldDirty: true,
            });
      }
    }
  };

  return (
    <>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleChange}
        wrapperClassName="editor-wrapper"
        editorClassName={error ? "editor-error" : "editor"}
        toolbar={toolbarOptions}
        readOnly={readOnly}
        placeholder={placeholder}
      />
      {error ? (
        <FormHelperText sx={{ color: "error.main", ml: 1.5 }}>
          {error}
        </FormHelperText>
      ) : null}
    </>
  );
};

export default FormRichTextEditor;
